import React, { useState } from 'react';
import { Rate } from 'antd';
import { useAppContext } from "../../../useAppState";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";

const SAVE_CHATBOT_SATISFACTION = gql`
  mutation SaveChatBotSatisfaction(
    $studentId: ID!, 
    $convoId: String!, 
    $satisfaction: Int!
    ) {
    saveChatBotSatisfaction(
        studentId: $studentId, 
        convoId: $convoId, 
        satisfaction: $satisfaction
    )
  }
`;

const Satisfaction = (props) => {

    const { t } = useTranslation();
    const { state } = useAppContext();
    const [value, setValue] = useState(null);

    const satisfactonLabels = [
        t('satisfactionVeryDissatisfied'),
        t('satisfactionDissatisfied'),
        t('satisfactionNeutral'),
        t('satisfactionSatisfied'),
        t('satisfactionVerySatisfied'),
    ]

    const [saveChatBotSatisfaction] = useMutation(
        SAVE_CHATBOT_SATISFACTION,
        {
            onCompleted(response) {
                console.log(response.saveChatBotSatisfaction);
            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );

    return (
        <div className="satisfaction-container">
            <Rate
                className="satisfaction-feature"
                tooltips={satisfactonLabels}
                onChange={(rating) => {
                    if (value === null) props.actionProvider.handleSatisfactionAnswered()

                    saveChatBotSatisfaction({
                        variables: {
                            studentId: parseInt(state.userInfos.id),
                            convoId: props.messages[0].id.toString(),
                            satisfaction: rating
                        }
                    });

                    setValue(rating)
                }}
                value={value} />

            {
                value ? <div className="satisfaction-text">{satisfactonLabels[value - 1]}</div> : null
            }
        </div>
    );
};

export default Satisfaction;