import Options from "./Options";
import { useTranslation } from "react-i18next";

const GeneralOptions = (props) => {
    const { t } = useTranslation();

    const options = props.state.overviewQuestions.map((el, elIndex) => {
        const indexChatbotQuestion = props.state.chatBotQuestions.findIndex(laQuestion => laQuestion.question === el.question);
        return {
            name: el.question,
            handler: props.actionProvider['handleQ' + (indexChatbotQuestion + 1)],
            id: elIndex + 2
        }
    });
    return <Options options={options} title={t('overviewExamplesQuestions')} {...props} />;
};

export default GeneralOptions;