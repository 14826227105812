import Cookies from 'js-cookie'
import { ApolloClient, split, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';


let localCallUri = process.env.REACT_APP_ENV === 'production' ? 'https://admin.icite.intactfc.com/graphql' : 'https://admin.icite.intactfc.com/dev/graphql';
// let localCallUriWS = process.env.REACT_APP_ENV === 'production' ? 'wss://admin.icite.intactfc.com/subscriptions' : 'wss://admin.icite.intactfc.com/dev/subscriptions';


const httpLink = createHttpLink({
    uri: localCallUri,
});

// const wsLink = new GraphQLWsLink(createClient({
//     url: localCallUriWS,
//     connectionParams: {
//         authToken: Cookies.get('token'),
//     },
// }));


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = Cookies.get('token');

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            lang: Cookies.get('lang') ? Cookies.get('lang') : 'fr'
        }
    }
});

const concactedLink = authLink.concat(httpLink);

// const splittedLink = split(
//     ({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     concactedLink,
// );

const cache = new InMemoryCache({
    dataIdFromObject: object => object.key || null
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
};

const client = new ApolloClient({
    link: concactedLink,
    cache: cache,
    defaultOptions: defaultOptions
});


export { client }