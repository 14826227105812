import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const MoreQuestionsYesOrNo = (props) => {
    const { t } = useTranslation();
    const [choiceSelected, setChoiceSelected] = useState(null)

    return (
        <div className="options">
            <div className="options-header">{props.title}</div>
            <div className="options-container">
                <div className={`option-item ${choiceSelected && choiceSelected === 'yes' ? 'option-item-is-selected' : ''} ${choiceSelected ? 'option-item-disabled' : ''}`} onClick={() => {
                    setChoiceSelected('yes')
                    props.actionProvider.handleYesMoreQuestions()
                }}>
                    {t('moreQuestionsYesOrNoYes')}
                </div>
                <div className={`option-item ${choiceSelected && choiceSelected === 'no' ? 'option-item-is-selected' : ''} ${choiceSelected ? 'option-item-disabled' : ''}`} onClick={() => {
                    setChoiceSelected('no')
                    props.actionProvider.handleNoMoreQuestions()
                }}>
                    {t('moreQuestionsYesOrNoNo')}
                </div>
            </div>
        </div>
    );
};

export default MoreQuestionsYesOrNo;